
import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'
// import { rules } from '@/validation/rules'
import { mapGetters } from 'vuex'
import { Prop, PropSync } from 'vue-property-decorator'
import Rezerv from '@/apis/Rezerv'
// import { RezervStatusId } from '@/typings/AdminTyping'
import WS from '@/apis/WS'

@Component({
  name: 'RezervLcModal',
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
})

export default class RezervLcModal extends Vue {
  @PropSync('isShowModal', { type: Boolean }) syncShowModal!: boolean
  @PropSync('idOfferModal', { type: Number }) syncIdOffer!: number
  @PropSync('needUpdateDealer', { type: Boolean }) syncNeedUpdateDealer!: boolean
  @PropSync('typeModal', { type: Number }) syncTypeModal!: number
  @Prop(String) requestId: any
  authUser = ''
  cancelStatuses = []
  cancelStatusSelect = ''
  cancelText = ''

  created() {
    this.authUser = this.$store.getters['auth/user']
    this.getCancelStatuses()
  }

  getCancelStatuses() {
    Rezerv.getCancelStatuses()
      .then(({ data }) => {
        if (data.success === true) {
          this.cancelStatuses = data.result
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        const msg = 'Что-то пошло не так'
        // @ts-ignore
        this.showErrorSystemNotification(msg)
      })
  }

  addRezerv() {
    // @ts-ignore
    Rezerv.addRezervLc(this.syncIdOffer, this.authUser.id)
      .then(({ data }) => {
        if (data.success === true) {
          // @ts-ignore
          this.showCongratsSystemNotification(data.msg)
          this.syncShowModal = false
          this.syncNeedUpdateDealer = true
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        const msg = 'Что-то пошло не так'
        // @ts-ignore
        this.showErrorSystemNotification(msg)
      })
  }

  cancelRezerv() {
    // @ts-ignore
    Rezerv.cancelRezervLc(this.syncIdOffer, this.cancelStatusSelect, this.cancelText)
      .then(({ data }) => {
        if (data.success === true) {
          // @ts-ignore
          this.showCongratsSystemNotification(data.msg)
          this.syncShowModal = false
          this.syncNeedUpdateDealer = true
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        const msg = 'Что-то пошло не так'
        // @ts-ignore
        this.showErrorSystemNotification(msg)
      })
  }

  confirmRezerv() {
    Rezerv.confirmRezerv(this.syncIdOffer, this.requestId)
      .then(({ data }) => {
        if (data.success === true) {
          if (data?.userIds.length) {
            WS.sendMessage('notification', {
              type: 'request_has_rezerv',
              // noAdmins: true,
              request_id: this.requestId,
              to_user_id: data.userIds,
              user_id: this.$store.state.auth.user.id
            })
          }

          // @ts-ignore
          this.showCongratsSystemNotification(data.msg)
          this.syncShowModal = false
          this.syncNeedUpdateDealer = true
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        const msg = 'Что-то пошло не так'
        // @ts-ignore
        this.showErrorSystemNotification(msg)
      })
  }

  cancelRezervDs() {
    Rezerv.cancelRezervDs(this.syncIdOffer)
      .then(({ data }) => {
        if (data.success === true) {
          // @ts-ignore
          this.showCongratsSystemNotification(data.msg)
          this.syncShowModal = false
          this.syncNeedUpdateDealer = true
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        const msg = 'Что-то пошло не так'
        // @ts-ignore
        this.showErrorSystemNotification(msg)
      })
  }
}
