import { Http } from '@/apis/Http'
// import Csrf from '@/apis/Csrf'

export default {
  updateCp(respId: number) {
    return Http.post('/search-leas-object/update-cp', {
      respId: respId
    })
  }
}
