<template>
  <v-card>
    <v-card-title class="chat-black-101">
      {{userName}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div>
        Вы действительно хотите переместить запрос в архив? Автосалоны не смогут добавлять новые предложения по запросу
      </div>
    </v-card-text>
    <v-card-actions class="d-block">
      <div class="mb-3"><v-btn large block color="primary" @click="submit()" outlined>Переместить в архив</v-btn></div>
      <div class="mb-5"><v-btn block large color="error" @click="$emit('close')" outlined>Отмена</v-btn></div>
    </v-card-actions>
  </v-card>
</template>
<script>
import { Http } from '@/apis/Http'
import WS from '@/apis/WS'

export default {
  props: ['userName', 'requestId', 'dsManagersIds'],
  methods: {
    async submit() {
      this.$emit('close')
      this.showSuccessSystemNotification('Запрос перемещен в архив')
      await Http.post('/search-leas-object/to-archive', { id: this.requestId, dsManagersIds: this.dsManagersIds })
      WS.sendMessage('notification', {
        type: 'request_to_archive',
        // noAdmins: true,
        request_id: this.requestId,
        to_user_id: this.dsManagersIds,
        user_id: this.$store.state.auth.user.id
      })
      if (this.$store.state.auth.userRole === 'admin') {
        this.$router.push('/admin/car-search-requests')
      } else {
        this.$router.push('/search-leas-objects')
      }
    }
  }
}
</script>
