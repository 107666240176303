<template>
  <div class="d-flex flex-column">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <div class="caption-text mb-1">Лизинговая компания</div>
        <div class="body-l-text"> {{ leasObjectDetails.leasingCompanyName }} </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="2"
        v-if="leasObjectDetails.managerLcName!==null"
      >
        <div class="caption-text mb-1">Менеджер</div>
        <div class="body-l-text"> {{ leasObjectDetails.managerLcName }} </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="2"
        v-if="leasObjectDetails.managerLcName!==null"
      >
        <div class="caption-text mb-1">Телефон</div>
        <div class="body-l-text"> {{ leasObjectDetails.managerLcPhone }} </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="2"
        v-if="leasObjectDetails.managerLcName!==null"
      >
        <div class="caption-text mb-1">Email</div>
        <div class="body-l-text"> {{ leasObjectDetails.managerLcEmail }} </div>
      </v-col>
      <v-col cols="12" sm="6" xl="3" md="6" class="text-xl-right">
        <div v-if="leasObjectDetails.chat && isDealer()">
          <v-btn :disabled="leasObjectDetails.archived" v-if="!leasObjectDetails.chat.id" class="go-to-chat-btn" color="grey" large outlined @click="chatLink()">
            <v-icon class="mr-2">mdi-message-outline</v-icon> Написать сообщение
          </v-btn>
          <v-btn :disabled="leasObjectDetails.archived" v-if="leasObjectDetails.chat.id" class="go-to-chat-btn" color="green" large outlined @click="chatLink()">
            <span v-if="!leasObjectDetails.chat.notViewed">
              <v-icon class="mr-2">mdi-message-outline</v-icon> Написать сообщение
            </span>
            <span v-else>
              <v-badge :content="leasObjectDetails.chat.notViewed" color="green">
                <v-icon>mdi-message-outline</v-icon>
              </v-badge>
              Новое сообщение
            </span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-6"></v-divider>
    <div class="subhead-text text-color--secondary mb-6">Информация о транспорте</div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <div class="caption-text mb-1">Марка авто</div>
        <div class="body-l-text"> {{ leasObjectDetails.carBrand }} </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <div class="caption-text mb-1">Модель авто</div>
        <div class="body-l-text"> {{ leasObjectDetails.carModel }} </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <div class="caption-text mb-1">Город(а)</div>
        <div class="body-l-text d-flex flex-wrap">
          <v-chip
            v-for="(city, index) in leasObjectDetails.cities"
            :key="index"
            color="primary"
            class="mr-1"
          >
            {{ city }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <div class="caption-text mb-1">Комплектация</div>
        <div class="body-l-text"> {{ leasObjectDetails.equipment }} </div>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <div class="caption-text mb-1">Бюджет на авто, до</div>
        <div class="body-l-text"> {{ translateBudget(leasObjectDetails.budget) }} </div>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="$store.state.auth.userRole != 'manager-ds'">
        <div class="caption-text mb-1">Имя клиента или ID в вашей CRM</div>
        <div class="body-l-text"> {{ leasObjectDetails.client }} </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <div class="caption-text mb-1">Тип</div>
        <div class="body-l-text"> {{ leasObjectDetails.is_truck ? 'Грузовой' : 'Легковой' }} </div>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <div class="caption-text mb-1">Состояние</div>
        <div class="body-l-text"> {{ leasObjectDetails.with_mileage ? 'С пробегом' : 'Новый' }} </div>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="leasObjectDetails.with_mileage">
        <div class="caption-text mb-1">Год выпуска, до</div>
        <div class="body-l-text"> {{ leasObjectDetails.year }} </div>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="leasObjectDetails.with_mileage">
        <div class="caption-text mb-1">Пробег (км.), до</div>
        <div class="body-l-text"> {{ translateMileage(leasObjectDetails.mileage) }} </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <div class="caption-text mb-1">Комментарий</div>
        <div class="body-l-text"> {{ leasObjectDetails.comment }} </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Http } from '@/apis/Http'
import { stringUtils } from '@/utils/string'

export default {
  props: ['leasObjectDetails'],

  mounted() {
    this.$socket.on('new_message', data => {
      if (this.$route.name !== 'RequestLeasObject') return
      if (data.request_id === this.leasObjectDetails.id) {
        ++this.leasObjectDetails.chat.notViewed
        this.leasObjectDetails.chat.id = data.chat_id
      }
    })
    console.log(this.$route.name)
  },

  methods: {
    isDealer() {
      return this.$store.state.auth.userRole === 'manager-ds'
    },

    chatLink() {
      const url = this.leasObjectDetails.chat.id
        ? `/chat/${this.leasObjectDetails.chat.id}`
        : `/chat/?user_id=${this.leasObjectDetails.managerLcId}&request_id=${this.leasObjectDetails.id}`
      if (this.leasObjectDetails.chat.id) Http.get('/chat/user-ds-click-to-chat', { params: { chat_id: this.leasObjectDetails.chat.id } })
      this.$router.push(url)
    },

    translateBudget(price) {
      return price ? stringUtils.priceDisplay(price) : ''
    },

    translateMileage(price) {
      return price ? stringUtils.priceDisplay(price, ' км') : ''
    }
  }
}
</script>
