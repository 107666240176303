import _ from 'lodash'

export const objectUtils = {
  sortByName(items: any) {
    return items.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  },

  sortByPrice(items: any) {
    return items.sort((a: any, b: any) => (a.carPrice > b.carPrice) ? 1 : ((b.carPrice > a.carPrice) ? -1 : 0))
  },

  sortByPriceDesc(items: any) {
    return items.sort((a: any, b: any) => (a.carPrice < b.carPrice) ? 1 : ((b.carPrice < a.carPrice) ? -1 : 0))
  },

  moscowOnTop(cities: any) {
    const moscowIndex = _.findIndex(cities, function (o: any) {
      return o.name === 'Москва'
    })
    const moscow = cities.splice(moscowIndex, 1)
    cities.unshift(...moscow)
    return cities
  }
}
