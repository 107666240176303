
import Component from 'vue-class-component'
import { Mixins, Prop, PropSync } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'
import { stringUtils } from '@/utils/string'
import { system } from '@/utils/system'
import { Http } from '@/apis/Http'
import LC from '@/apis/LC'

// import { UserRoles } from '@/typings/UserTypings'
import { mapGetters } from 'vuex'

@Component({
  name: 'DealershipResponse',
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
})

export default class DealershipResponse extends Mixins(TableMixin) {
  // @ts-ignore
  @Prop(Array | Object) dealershipResponse!: any
  @Prop(Number) requestId: any
  @Prop(Boolean) archived: any
  @PropSync('dealershipResponse', { type: Object }) syncdealershipResponse!: object
  @PropSync('isShowRezervModal', { type: Boolean }) syncShowModalDealer!: boolean
  @PropSync('idOfferDealer', { type: Number }) syncIdOfferDealer!: number
  @PropSync('typeModal', { type: Number }) syncTypeModal!: number

  simpleTable = true
  itemsPerPage = 20
  userRole = ''

  created() {
    this.userRole = this.$store.getters['auth/userRole']
  }

  translateCarPrice(price) {
    return stringUtils.priceDisplay(price)
  }

  cpLink(path: any) {
    return system.getFileLink(path)
  }

  chatLink(item) {
    const url = item.chat.chat_id ? `/chat/${item.chat.chat_id}` : `/chat/?user_id=${item.user.id}&request_id=${this.requestId}`
    if (item.chat.chat_id) Http.get('/chat/user-ds-click-to-chat', { params: { chat_id: item.chat.chat_id } })
    this.$router.push(url)
  }

  updateCp(responseId: number, index: number) {
    LC.updateCp(responseId)
      .then(response => {
        this.dealershipResponse.items[index].cpDate = response.data.result.cpDate
      })
      .catch(() => {
        // this.showErrorSystemNotification('Что-то пошло не так!')
      })
      .finally(() => {
        // @ts-ignore
        this.$setLoading(false)
      })
  }

  addRezerv(respId: number) {
    this.syncIdOfferDealer = respId
    this.syncShowModalDealer = true
    this.syncTypeModal = 1
  }

  cancelRezerv(rezervId: number) {
    this.syncIdOfferDealer = rezervId
    this.syncTypeModal = 2
    this.syncShowModalDealer = true
  }

  checkOtherRezerv(id: number) {
    let showBtn = true
    for (const item of this.dealershipResponse.items) {
      if (
      item.rezerv &&
      (
      item.rezerv.rezerv_status_id === 1 ||
      item.rezerv.rezerv_status_id === 2 ||
      item.rezerv.rezerv_status_id === 3 ||
      item.rezerv.rezerv_status_id === 4
      ) &&
      item.id !== id
      ) {
        showBtn = false
      }
    }

    return showBtn
  }
}
