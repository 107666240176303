import { Http } from '@/apis/Http'
// import Csrf from '@/apis/Csrf'

export default {
  addRezervLc(respId: number, userId: number) {
    return Http.post('/search-leas-object/add-rezerv', {
      respId: respId,
      userId: userId
    })
  },
  cancelRezervLc(rezrvId: number, cancelStatusSelect: number, cancelText: string) {
    return Http.post('/search-leas-object/del-rezerv', {
      rezrvId: rezrvId,
      cancelStatusSelect: cancelStatusSelect,
      cancelText: cancelText
    })
  },
  confirmRezerv(rezrvId: number, requestId: any) {
    return Http.post('/search-leas-object/confirm-rezerv', {
      rezrvId: rezrvId,
      requestId
    })
  },
  cancelRezervDs(rezrvId: number) {
    return Http.post('/search-leas-object/cancel-rezerv-ds', {
      rezrvId: rezrvId
    })
  },
  getCancelStatuses() {
    return Http.get('/search-leas-object/get-cancel-statuses', {})
  }
}
