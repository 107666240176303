import Vue from 'vue'

const Socket = Vue.prototype.$socket

export default {
  sendMessage(name, data = {}, callback = null) {
    if (!Socket) {
      console.log('not connected')
      return
    }
    if (callback) {
      Socket.emit(name, data, res => callback(res))
    } else {
      Socket.emit(name, data)
    }
  }
}
