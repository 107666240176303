
  import Vue from 'vue'
  import Component from 'vue-class-component'
  import moment from 'moment'

  import LeasObjectDetails from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/LeasObjectDetails.vue'
  import DealershipResponse
    from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/components/DealershipResponse.vue'
  import { Http } from '@/apis/Http'
  import { Watch } from 'vue-property-decorator'
  import _ from 'lodash'
  import { objectUtils } from '@/utils/object'
  import ArchiveDialog from '@/views/SearchLeasObject/components/ArchiveDialog.vue'
  import FromArchiveDialog from '@/views/SearchLeasObject/components/FromArchiveDialog.vue'
  import RezervLcModal from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/components/RezervLсModal.vue'
  import EmptyStateCard from '@/views/components/EmptyStateCard.vue'

  @Component({
    name: 'SearchLeasObjectDetails',
    components: {
      LeasObjectDetails,
      DealershipResponse,
      ArchiveDialog,
      FromArchiveDialog,
      RezervLcModal,
      EmptyStateCard
    },
    watch: {
      $route(to, from) {
        // console.log(to, from, 555)
        if (to.name === 'SearchLeasObjectDetails' && from.name === 'SearchLeasObjectDetails' && to.path !== from.path) {
          // @ts-ignore
          this.init()
        }
      }
    }
  })

  export default class SearchLeasObjectDetails extends Vue {
    needUpdate = false;

    @Watch('needUpdate')
    onNeedUpdateChange(val: boolean) {
      if (val) {
        Http.get('/search-leas-object/get-details', {
          params: {
            id: this.$route.params.id
          }
        })
          .then(response => {
            this.dealershipResponses = this.sortDsByPrices(response.data.dealershipResponses)
            this.leasObjectDetails = response.data.leasObjectDetails
            this.needUpdate = false
            this.getKpChatCount()
          })
          .catch(() => {
            // this.showErrorSystemNotification('Что-то пошло не так!')
          })
          .finally(() => {
            // @ts-ignore
            this.$setLoading(false)
          })
      }
    }

    showModalRez = false
    idOffer = null
    typeModal = 1; // 1 - добавить резерв, 2 - отменить резерв
    requestLeasObject = {
      id: 1,
      date: '',
      leasObjectDetails: {
        brand: '',
        model: '',
        cities: [],
        comment: ''
      }
    }

    leasObjectDetails = {}
    dealershipResponses = []
    archiveDialog = false
    fromArchiveDialog = false
    dsManagersIds = []
    isOwnRequest = true

    get requestDate(): string {
      return moment(this.requestLeasObject.date).format('LLL')
    }

    created() {
      this.init()
    }

    init() {
      // @ts-ignore
      this.$setLoading(true)
      Http.get('/search-leas-object/get-details', {
        params: {
          id: this.$route.params.id
        }
      })
        .then(response => {
          if (response.data.error && response.data.msg === 'not-own-request') {
            this.isOwnRequest = false
            return
          }

          this.dealershipResponses = this.sortDsByPrices(response.data.dealershipResponses)
          response.data.leasObjectDetails.archived = !!response.data.leasObjectDetails.archived
          this.leasObjectDetails = response.data.leasObjectDetails
          for (const dealershipRes of this.dealershipResponses) {
            // @ts-ignore
            for (const item of dealershipRes.items) {
              // @ts-ignore
              if (!this.dsManagersIds.includes(item.user.id)) this.dsManagersIds.push(item.user.id)
            }
          }
          this.getKpChatCount()
        })
        .catch(() => {
          // this.showErrorSystemNotification('Что-то пошло не так!')
        })
        .finally(() => {
          // @ts-ignore
          this.$setLoading(false)
        })

      // @ts-ignore
      this.$socket.on('new_message', (data: any) => {
        console.log('req d', data)
        // @ts-ignore
        if (data.request_id === this.leasObjectDetails.id) {
          this.setItemChatCount(data.dealership_id, 1)
        }
      })
    }

    getKpChatCount() {
      // @ts-ignore
      Http.get('/chat/get-kp-chat-count', { params: { ids: this.dsManagersIds, request_id: this.leasObjectDetails.id } })
        .then(response => {
          for (const dealershipRes of this.dealershipResponses) {
            // @ts-ignore
            for (const item of dealershipRes.items) {
              item.chat = response.data?.[item.user.id]
            }
          }
        })
    }

    setItemChatCount(dealershipId, count) {
      // @ts-ignore
      const dealershipRes = this.dealershipResponses.find(el => el.id === dealershipId)
      if (!dealershipRes) return
      // @ts-ignore
      for (const item of dealershipRes.items) {
        item.chat.notViewed += parseInt(count)
      }
    }

    sortDsByPrices(data) {
      data.forEach(function (item) {
        return objectUtils.sortByPrice(item.items)
      })
      return data.sort((a, b) => (this.maxPrice(a.items) > this.maxPrice(b.items))
        ? 1 : ((this.maxPrice(b.items) > this.maxPrice(a.items)) ? -1 : 0))
    }

    maxPrice(items) {
      const obj = _.minBy(items, 'carPrice')
      // @ts-ignore
      return (obj !== undefined) ? obj.carPrice : Infinity
    }

    endRequest() {
      // @ts-ignore
      this.showSuccessSystemNotification('Ваша заявка закрыта')
    }
  }
